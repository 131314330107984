import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { CreateDepositModel, DepositModel } from '../../models/deposit.model';
import BigNumber from 'bignumber.js';
import { WithdrawalModel } from '../../models/withdrawal.model';
import { WithdrawalResponseModel } from '../../models/withdrawal-response.model';
import { WithdrawalRequests } from '../../models/withdrawal-requests.model';
import { TransactionStatus } from '../../models/transaction.status.enum';
import { Banks } from '../../models/banks.model';
import { LocalStorageService } from 'angular-web-storage';
import { LocalStorageKeys } from '../../utils/local.storage.keys';
import { WithdrawalRequestsFavorites } from '../../models/WithdrawalRequestsFavorites';

@Injectable({ providedIn: 'root' })
export class DepositsService {

    constructor(
        private readonly http: HttpClient,
        private readonly localStorage: LocalStorageService
    ) {
    }

    public getUserDeposits(): Observable<Array<DepositModel>> {
        return this.http.get(RestEndpoint.getUserDeposits, {})
            .pipe(
                map((data: any) => {
                    return data.body;
                }),
                catchError((err) => {
                    throw(err);
                })
            );

    }

    public getJournal(): Observable<any> {
        return this.http.get(RestEndpoint.journal, {})
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw(err);
                })
            );
    }

    public createDeposit(data: CreateDepositModel): Observable<BigNumber> {
        return this.http.post<BigNumber>(RestEndpoint.createDepositRequest, data)
            .pipe(
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getUserWithdrawal(from?: Date, to?: Date): Observable<Array<WithdrawalResponseModel>> {
        return this.http.get(RestEndpoint.getUserWithdrawal, { params: {
            'from': from.getTime().toString(),
            'to': to.getTime().toString()
        } })
            .pipe(
                map((data: any) => {
                    return data.body;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getUserWithdrawalFavorites(): Observable<Array<WithdrawalRequestsFavorites>> {
        return this.http.get(RestEndpoint.getUserWithdrawalFavorites, { params: {} })
            .pipe(
                map((data: any) => {
                    return data.body;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public createWithdrawal(request: any): Observable<any> {
        return this.http.post<WithdrawalModel>(RestEndpoint.createWithdrawRequest, request)
            .pipe(
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getAllWithdrawalRequest(): Observable<Array<WithdrawalRequests>> {
        return this.http.get(RestEndpoint.getAllWithdrawalRequest, {})
            .pipe(
                map((data: any) => {
                    return data.body;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public updateWithdrawal(id: string, status: TransactionStatus): Observable<any> {
        return this.http.put(RestEndpoint.updateWithdrawRequest, { requestId: id, status })
            .pipe(
                catchError((err) => {
                    throw err;
                })
            );
    }

    public cleanListOfBanks(): void {
        this.localStorage.remove(LocalStorageKeys.LIST_OF_BANKS);
    }

    public getListOfBanksCached(): Banks[] {
        const banksStorage = this.localStorage.get(LocalStorageKeys.LIST_OF_BANKS)
        if (!banksStorage){
            return new Promise(resolve=>{
                this.getListOfBanks().subscribe(data => {
                    resolve(data);
                });
            }) as any;
        }
        return banksStorage;
    }

    public getListOfBanks(): Observable<Banks[]> {
        return this.http.get(RestEndpoint.getListOfBanks, {})
            .pipe(
                map((data: {body: Banks[]}) => {
                    this.localStorage.set(LocalStorageKeys.LIST_OF_BANKS, data.body);
                    return data.body;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }
}

 