import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FeatureNames } from './shared/models/feature-names.enum';
import { FeaturesStatusService } from './shared/services/util/features-status.service';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from '../environments/environment'
import { TranslationConstants } from './shared/services/util/translation.constants';
import { UXConfigsService } from './shared/services/util/ux-configs.service';
import { AppService } from './app.service';
import { MatIconRegistry } from '@angular/material/icon';
import { AccountService } from './shared/services/account/account.services';
import { FintechAccountStatus } from './shared/models/BankingAccount';
import { HomeService } from './shared/services/account/home.service';
import { LocalStorageService } from 'angular-web-storage';
import { LocalStorageKeys } from './shared/utils/local.storage.keys';
import { OneSignal } from 'onesignal-ngx';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public title: string = 'Midas';
    public showBottom: boolean = false;
    public hideExternalHeader: boolean = false;
    public hideBottom: boolean = false;
    public hideExternalFooter: boolean = true;
    public viewComponent: boolean = false;
    public svgIcons: Array<string> = [
        'apple',
        'arrow-down',
        'bank-slip',
        'benefits',
        'consortium',
        'chat-no-user',
        'deposits',
        'document-key',
        'draft',
        'escrow',
        'forward',
        'google-play',
        'pix',
        'random-key',
        'transfers',
        'loan',
        'linkedin',
        'pix-copyandpaste',
        'pix-history',
        'pix-keys',
        'pix-qrcode',
        'pix-receive',
        'pix-transfer',
        'safe',
        'swap',
        'health',
        'instagram',
        'twitter',
        'back-side-document',
        'front-side-document',
        'selfie-with-document',
        "blockchain-logo",
        "stamp-sos"
    ];

    public oneSignal: OneSignal = new OneSignal();

    constructor(
        private readonly translate: TranslateService,
        private readonly router: Router,
        private readonly featuresStatusService: FeaturesStatusService,
        private readonly translatableService: TranslationConstants,
        private readonly storage: LocalStorageService,
        private readonly uxConfigsService: UXConfigsService,
        private readonly titleService: Title,
        private readonly appService: AppService,
        private readonly matIconRegistry: MatIconRegistry,
        private readonly homeService: HomeService,
        private readonly domSanitizer: DomSanitizer,
        private readonly renderer: Renderer2,
        private readonly accountService: AccountService
    ) {

        this.matIconRegistry.addSvgIcon(
            "blockchain",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/svgIcons/blockchain_icon.svg")
        );

        this.matIconRegistry.addSvgIcon("en", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/language/en.svg"));
        this.matIconRegistry.addSvgIcon("pt-br", this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/language/pt-br.svg"));

        this.translate.addLangs(['en', 'fr', 'pt-br', 'eninternal', 'pt-brinternal']);
        this.translate.setDefaultLang('pt-br');
        const browserLang: string = this.translate.getBrowserLang();

        if (browserLang.match(/pt/)) {
            this.translate.use('pt-br');
        } else {
            this.translate.use('en');
        }


        let displayMobile = (window.innerWidth || window.screen.width) <= 768;
        if(displayMobile) {
            this.showBottom = true;
        }

        this.translatableService.getTexts().subscribe();

        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                const currentUrl: string = event?.url;
                this.appService.setCurrentUrl(currentUrl);

                if (currentUrl?.includes('navigation') ||
                    currentUrl?.includes('digital-banking/new-account') ||
                    currentUrl?.includes('digital-banking/external') ||
                    currentUrl?.includes('marketplace') ||
                    currentUrl === '/' ||
                    currentUrl?.includes('events') ||
                    currentUrl?.includes('tokenization')
                ) {
                    this.hideExternalHeader = true;
                } else {
                    this.hideExternalHeader = false;
                }

                if (
                    currentUrl?.includes('digital-banking/new-account') ||
                    currentUrl?.includes('digital-banking/external') ||
                    currentUrl === '/' ||
                    currentUrl?.includes('account')
                ) {
                    this.hideBottom = true;
                } else {
                    this.hideBottom = false;
                }
    
                if (currentUrl?.includes('digital-banking/new-account') ||
                    currentUrl?.includes('digital-banking/external') ||
                    currentUrl?.includes('marketplace') ||
                    currentUrl?.includes('account') ||
                    currentUrl === '/' ||
                    currentUrl?.includes('events') ||
                    currentUrl?.includes('tokenization')
                ) {
                    this.hideExternalFooter = true;
                } else {
                    this.hideExternalFooter = false;
                }
                this.checkHideHeaderAndFooter(currentUrl);
            }
        });
        this.registrySvgIcons();
        this.renderer.addClass(document.body, 'custom-scrollbar');


        this.uxConfigsService.getOrderOfHomeSections().subscribe(data => {
            if (data && data?.length > 0) {
                this.homeService.sectionList = data;
            }
            this.homeService.loaded = true;
        });

    }

    public ngOnInit(): void {
        if (environment?.pageTitle){
            this.titleService.setTitle(environment.pageTitle);
        }

        this.featuresStatusService.getAllFeatureStatus().subscribe(features => {
            for(let feature of Object.keys(features)) {
                this.storage.set(LocalStorageKeys.FEATURE_STATUS + feature, features[feature]);

                if(feature === FeatureNames.ONE_SIGNAL) {
                    this.oneSignal.init({
                        appId: environment.oneSignalId,
                        serviceWorkerParam: {
                            scope: '/onesignal'
                        }
                      });
                }
            }
        });
        this.uxConfigsService.getEniatoHeaderLinks(true).subscribe();
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    public registrySvgIcons(): void {
        this.svgIcons.forEach((imageName: string) => {
            this.matIconRegistry.addSvgIcon(
                imageName,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`../assets/svg-icons/${imageName}.svg`)
            );
        });
    }

    public checkHideHeaderAndFooter (currentUrl: string): void {
        this.accountService.getLoggedUserDetails().subscribe(user => {
            if (user) {
                this.viewComponent = true;
                // if the user is logged in but does not have access to the digital bank
                if (
                    (
                        !user?.bankingAccount ||
                        user?.bankingAccount?.fintechStatus !== FintechAccountStatus.APPROVED
                    ) &&
                    currentUrl?.includes('/digital-banking')
                ) {
                    this.viewComponent = false;
                }
            } else {
                this.viewComponent = false;
            }
        });
    }
}