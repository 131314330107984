<div id="header" class="header">
    <div class="logo-container" (click)="goHome()">
        <img src="/assets/images/logo_white.svg" alt="logo" class="logo">
    </div>

    <div class="sidebar" (click)="toggleSidenav()" *ngIf="!hideSidebarIcon && currentPlatform === platform.WALLET">
        <mat-icon class="menu-icon">menu</mat-icon>
    </div>

    <div class="user-menus-container">
        <div class="user-data" *ngIf="!hideMediasIcon">
            <div class="inline change-navigation" *ngIf="isMultiplatform">
                <mat-icon *ngIf="walletEnabled" (click)="navigateTo('/home')" [ngClass]="{'active': currentPlatform === platform.WALLET}"
                    class="medias-icon" [matTooltip]="'header.menuUser.digitalWallet' | translate" [matTooltipClass]="'tooltip-info'">account_balance_wallet</mat-icon>
                <mat-icon *ngIf="digitalBankingEnabled" (click)="navigateTo('/digital-banking')" [ngClass]="{'active': currentPlatform === platform.BANKING}"
                    class="medias-icon" [matTooltip]="'header.menuUser.digitalBanking' | translate" [matTooltipClass]="'tooltip-info'">payments</mat-icon>
                <mat-icon *ngIf="marketplaceEnabled" (click)="navigateTo('/marketplace')" [ngClass]="{'active': currentPlatform === platform.MARKETPLACE}"
                class="medias-icon" [matTooltip]="'header.menuUser.marketplace' | translate" [matTooltipClass]="'tooltip-info'">store</mat-icon>
                <mat-icon *ngIf="tokenizationEnabled" (click)="navigateTo('/tokenization')" [ngClass]="{'active': currentPlatform === platform.TOKENIZATION}"
                class="medias-icon" [matTooltip]="'header.menuUser.tokenization' | translate" [matTooltipClass]="'tooltip-info'">assessment</mat-icon>
                <mat-icon *ngIf="crowdfundingEnabled" (click)="navigateTo('/tokenization/crowdfunding')" [ngClass]="{'active': currentPlatform === platform.CROWDFUNDING}"
                class="medias-icon" [matTooltip]="'header.menuUser.crowdfunding' | translate" [matTooltipClass]="'tooltip-secondary'">business</mat-icon>
                <mat-icon *ngIf="chatEnabled" (click)="navigateTo('/chat')" [ngClass]="{'active': currentPlatform === platform.CHAT}"
                class="medias-icon" [matTooltip]="'header.menuUser.chat' | translate" [matTooltipClass]="'tooltip-info'">forum</mat-icon> 
            </div>
            <mat-icon fontSet="material-icons-round" [matMenuTriggerFor]="more" class="medias-icon">more_horiz</mat-icon>
            <mat-menu class="medias-menu" #more="matMenu" xPosition="after">
                <button mat-menu-item (click)="goToUrl('aboutUs')">
                    {{ 'header.contacts.aboutCompany' | translate }}
                </button>
                <button mat-menu-item (click)="goToUrl('policies/privacy')">
                    {{ 'header.contacts.privacyPolicy' | translate }}
                </button>
                <button *ngIf="externalLinks?.faq" mat-menu-item (click)="goToUrl('faq')">
                    {{ 'header.contacts.faq' | translate }}
                </button>
                <button mat-menu-item (click)="goToContact()">
                    {{ 'header.contacts.contact' | translate }}
                </button>
                <button mat-menu-item (click)="goToDelete()">
                    {{ 'header.contacts.exclude' | translate }}
                </button>
                <div class="social-networks">
                    <p class="social-networks-title">{{ 'header.contacts.socialNetworks' | translate }}</p>
                    <div class="medias" *ngFor="let socialMedia of socialMedias" (click)="goToUrl('socialMidias/' + socialMedia)">
                        <button mat-menu-item>
                            <img class="media-image" [src]="'/assets/images/' + socialMedia + '.svg'" [alt]="socialMedia">
                            {{'header.contacts.' + socialMedia | translate}}
                        </button>
                    </div>
                </div>
            </mat-menu>
        </div>

        <div class="user-data">
            <mat-icon fontSet="material-icons-round" [matMenuTriggerFor]="menuUser" class="menu-icon">account_circle</mat-icon>
            <mat-menu class="user-menu" #menuUser="matMenu" xPosition="after">

                <section *ngIf="loggedUser?.firstName">
                <div class="change-navigation-menu" *ngIf="hideMediasIcon && (digitalBankingEnabled || marketplaceEnabled || tokenizationEnabled)">
                    <button *ngIf="walletEnabled" mat-menu-item routerLink="/home" [disabled]="currentPlatform === platform.WALLET">
                        <mat-icon [ngClass]="{'disabled': currentPlatform === platform.WALLET}"
                            class="medias-icon" [matTooltip]="'header.menuUser.digitalBanking' | translate" [matTooltipClass]="'tooltip-info'">account_balance_wallet</mat-icon>
                        {{ 'header.menuUser.digitalWallet' | translate }}
                    </button>
                    <button mat-menu-item routerLink="/digital-banking" [disabled]="currentPlatform === platform.BANKING" *ngIf="digitalBankingEnabled">
                        <mat-icon [ngClass]="{'disabled': currentPlatform === platform.BANKING}"
                            class="medias-icon" [matTooltip]="'header.menuUser.digitalBanking' | translate" [matTooltipClass]="'tooltip-info'">payments</mat-icon>
                        {{ 'header.menuUser.digitalBanking' | translate }}
                    </button>
                    <button mat-menu-item routerLink="/marketplace" [disabled]="currentPlatform === platform.MARKETPLACE" *ngIf="marketplaceEnabled">
                        <mat-icon [ngClass]="{'disabled': currentPlatform === platform.MARKETPLACE}"
                            class="medias-icon" [matTooltip]="'header.menuUser.marketplace' | translate" [matTooltipClass]="'tooltip-info'">store</mat-icon>
                        {{ 'header.menuUser.marketplace' | translate }}
                    </button>
                    <button mat-menu-item routerLink="/tokenization" [disabled]="currentPlatform === platform.TOKENIZATION" *ngIf="tokenizationEnabled">
                        <mat-icon [ngClass]="{'disabled': currentPlatform === platform.TOKENIZATION}"
                            class="medias-icon" [matTooltip]="'header.menuUser.tokenization' | translate" [matTooltipClass]="'tooltip-info'">assessment</mat-icon>
                        {{ 'header.menuUser.tokenization' | translate }}
                    </button>
                    <button mat-menu-item routerLink="/tokenization/crowdfunding" [disabled]="currentPlatform === platform.CROWDFUNDING" *ngIf="crowdfundingEnabled">
                        <mat-icon fontSet="material-icons-round" class="medias-icon" [matTooltip]="'header.menuUser.crowdfunding' | translate" 
                            [matTooltipClass]="'tooltip-secondary'">payments</mat-icon>
                            {{ 'header.menuUser.crowdfunding' | translate }}
                    </button>
                    <button mat-menu-item routerLink="/chat" [disabled]="currentPlatform === platform.EVENTS" *ngIf="chatEnabled">
                        <mat-icon [ngClass]="{'disabled': currentPlatform === platform.EVENTS}"
                            class="medias-icon" [matTooltip]="'header.menuUser.chat' | translate" [matTooltipClass]="'tooltip-info'">forum</mat-icon>
                        {{ 'header.menuUser.chat' | translate }}
                    </button>
                </div>
                <button mat-menu-item routerLink="/account/forgot">
                    {{ 'header.menuUser.changePassword' | translate }}
                </button>
                <button mat-menu-item routerLink="/profile">
                    {{ 'header.menuUser.myProfile' | translate }}
                </button>
                <button (click)="navigateToInternalPage(item)" *ngFor="let item of cards" mat-menu-item>
                    {{ 'home.carouselCards.' + item?.name + '.title' |  translate }}                
                </button>
                <button mat-menu-item routerLink="/tokenization/my-assets" *ngIf="tokenizationEnabled">
                    {{ 'header.menuUser.myAssets' | translate }}
                </button>
                <button mat-menu-item routerLink="/tokenization/payment-history" *ngIf="tokenizationEnabled && paymentQueueEnabled">
                    {{ 'header.menuUser.paymentQueue' | translate }}
                </button>
                <button mat-menu-item routerLink="/tokenization/crowdfunding/my-investments" *ngIf="crowdfundingEnabled">
                    {{ 'header.menuUser.myCrowdfunding' | translate }}
                </button>
                <button mat-menu-item (click)="copyIndication()">
                    {{ 'header.menuUser.copyIndication' | translate }}
                </button>
                <button mat-menu-item (click)="logout()">
                    {{ 'header.menuUser.logout' | translate }}
                </button>
                <hr class="medias-menu-responsive-divider">
                <div class="medias-menu-responsive" *ngIf="hideMediasIcon">
                    
                    <button mat-menu-item (click)="goToUrl('aboutUs')">
                        {{ 'header.contacts.aboutCompany' | translate }}
                    </button>
                    <button mat-menu-item (click)="goToUrl('policies/privacy')">
                        {{ 'header.contacts.privacyPolicy' | translate }}
                    </button>
                    <button *ngIf="externalLinks?.faq" mat-menu-item (click)="goToUrl('faq')">
                        {{ 'header.contacts.faq' | translate }}
                    </button>
                    <button mat-menu-item (click)="goToContact()">
                        {{ 'header.contacts.contact' | translate }}
                    </button>
                    <button mat-menu-item (click)="goToDelete()">
                        {{ 'header.contacts.exclude' | translate }}
                    </button>
                    <div class="social-networks">
                        <p class="social-networks-title">{{ 'header.contacts.socialNetworks' | translate }}</p>
                        <div class="medias" *ngFor="let socialMedia of socialMedias" (click)="goToUrl('socialMidias/' + socialMedia)">
                            <button mat-menu-item>
                                <img class="media-image" [src]="'/assets/images/' + socialMedia + '.svg'" [alt]="socialMedia">
                                {{'header.contacts.' + socialMedia | translate}}
                            </button>
                        </div>
                    </div>

                </div>
            </section>
            <section *ngIf="!loggedUser?.firstName">
                <button mat-menu-item routerLink="/account/login">
                    {{ 'header.login' | translate }}
                </button>
            </section>
            </mat-menu>
            <div class="user-name-container" [matMenuTriggerFor]="menuUser">
                <span class="user-name etc">{{ loggedUser?.firstName }}</span>
                <mat-icon fontSet="material-icons-round" class="down">keyboard_arrow_down</mat-icon>
            </div>
        </div>
    </div>
</div>

<mat-progress-spinner *ngIf="loading" class="spinner" [mode]="'indeterminate'"></mat-progress-spinner>