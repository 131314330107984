import { RestEndpoint } from '../../../constants/rest-endpoint.constants';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BillCheckRequest, IBillCheckResponse } from '../../models/bank-bill.model';
import { IBillPaymentRequest, IBillPaymentResponse } from '../../models/bill.payment.model';
import { IDigitalBankingBanners } from '../../models/IDigitalBankingBanners.model';
import { BankingAccount } from 'src/app/shared/models/BankingAccount';
import { IAccountHistoryResponse, AccountHistoryEntries, IAccountHistoryRequest } from 'src/app/shared/models/bank-history.model';
import { IInternalTransferRequest, IInternalTransferResponse } from 'src/app/shared/models/bank-internal-transfer-data.model';
import { IExternalTransferRequest, IExternalTransferResponse } from 'src/app/shared/models/bank-external-transfer-data.model';
import { PixKeysResponse } from '../../models/pix.keys.request.model';
import { PixDeletionResponse } from '../../models/pix.delete.model';
import { IPixCreationRequest, PixCreationResponse } from '../../models/pix.creation.model';
import { AccountCreationRequest } from '../../models/bank.account.creation.model';
import { QRCodeCreationRequest, QRCodeCreationResponse, QRCodeDataResponse, QRCodeEMVInputRequest, QRCodeEMVResponse, QRCodeInputRequest } from '../../models/pix.qrcode.model';
import { IAccountBalanceResponse, IAccountBalanceRequest } from '../../models/bank-balance.model';
import { IAccountInfoByPixKeyRequest, AccountInfoByPixKeyResponse } from '../../models/account-info-by-pix-key.model';
import { PixTransferRequest, PixTransferResponse } from '../../models/pix.transfer.model';
import { TransactionPasswordEncrypted, PayloadEncrypted } from './../../models/encrypt.base';
import { IAccountInfoRequest, IAccountInfoResponse, IAccountInfoLegalPersonResponse } from '../../models/account.info.request';
import { UpdateAccountInfoRequest } from '../../models/account.info.model';
import { AccountService } from './account.services';
import { createLoanRequest } from '../../models/bank-loan.model';
import { sendBenefitsEmailPayload } from '../../models/bank-benefits.model';
import { ContractPayments, ContractRequest, IContractPaymentStatus, IContractStatus } from '../../models/contract.request';
import BigNumber from 'bignumber.js';

@Injectable()
export class BankingService {

    private userBankingBalance: BehaviorSubject<number> = new BehaviorSubject<number>(undefined);
    public static RSA_KEY: string;
    private transactionPasswordEncrypted: string;
    public userBankingAccount: BankingAccount;

    constructor(
        private readonly http: HttpClient,
        private accountService: AccountService
    ) {
        this.accountService.getLoggedUserDetails().subscribe(user => this.userBankingAccount = user?.bankingAccount);
    }

    public getRsaPublicKey(): Observable<boolean | void> {
        if (BankingService.RSA_KEY) {
            return of();
        }

        return this.http.get(RestEndpoint.publicRsa, {})
            .pipe(
                map((data: any) => {
                    if (!data.value) {
                        throw new Error('Problems to retrieve RSA Key.');
                    }
                    BankingService.RSA_KEY = data.value;
                    return of();
                }),
                map(() => true),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getBillData(billData: BillCheckRequest): Observable<IBillCheckResponse>{
        return this.http.post(RestEndpoint.banking.bills.getBillData, billData)
            .pipe(
                map((userData: any) => {
                    return userData?.data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public payBill(billData: IBillPaymentRequest): Observable<IBillPaymentResponse>{

        const payloadEncrypted: PayloadEncrypted = new PayloadEncrypted(billData, BankingService.RSA_KEY);

        return this.http.post(RestEndpoint.banking.bills.payBill, {
            param0: this.transactionPasswordEncrypted,  
            param1: payloadEncrypted.payload
        })
            .pipe(
                map((data: IBillPaymentResponse) => {
                    const { accountId, documentNumber } = this.userBankingAccount;
                    this.getBalance({accountId, documentNumber}).subscribe();
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getPixKeys(): Observable<PixKeysResponse> {
        return this.http.get(RestEndpoint.banking.pix.getPixKeys, {})
            .pipe(
                map((res: {data: PixKeysResponse}) => {
                    if (!res?.data) {
                        throw new Error('Cannot retrieve the Pix key list');
                    }
                    return res.data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public createPixKey(pixCreation: IPixCreationRequest): Observable<PixCreationResponse> {

        return this.http.post(RestEndpoint.banking.pix.createPixKey, pixCreation)
            .pipe(
                map((response: PixCreationResponse) => {
                    return response;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public excludePixKey(key: string): Observable<PixDeletionResponse> {
        return this.http.post(RestEndpoint.banking.pix.excludePixKey, { key })
            .pipe(
                map((response: PixDeletionResponse) => {
                    return response;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public createLoan(createLoanRequest: createLoanRequest): Observable<any>{
        return this.http.post(RestEndpoint.createLoanRequests, createLoanRequest)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public sendBenefitsEmail(sendBenefitsEmailPayload: sendBenefitsEmailPayload): Observable<any>{
        return this.http.post(RestEndpoint.sendBenefitsEmail, sendBenefitsEmailPayload)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public create(accountCreationRequest: AccountCreationRequest): Observable<any>{
        const transactionPasswordEncrypted = this.pwdEncrypted(accountCreationRequest.transactionPassword);
        accountCreationRequest.transactionPassword = transactionPasswordEncrypted;

        return this.http.post(RestEndpoint.banking.account.create, accountCreationRequest)
            .pipe(
                map((bankingAccount: BankingAccount) => {
                    return bankingAccount;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public pwdEncrypted(pwd: string) {
        const transactionPasswordEncrypted : TransactionPasswordEncrypted = new TransactionPasswordEncrypted(pwd, BankingService.RSA_KEY);
        return transactionPasswordEncrypted.password;
    }

    public getBankingBanners(): Observable<Array<IDigitalBankingBanners>> {
        return this.http.get(RestEndpoint.banking.getAllDigitalBankingBanners, {})
            .pipe(
                map((data: Array<IDigitalBankingBanners>) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                })
            );
    }

    public getBalance(accountBalanceRequest: IAccountBalanceRequest): Observable<number>{
        return this.http.get(RestEndpoint.banking.account.getBalance, { params: { 
                accountId: accountBalanceRequest.accountId, 
                documentNumber: accountBalanceRequest.documentNumber }})
            .pipe(
                map((result: IAccountBalanceResponse) => {
                    const userBalance: number = result?.data?.body?.amount;
                    this.setBalanceSubscription(userBalance);
                    if (userBalance) {
                        return result?.data?.body?.amount;
                    } else {
                        return 0;
                    }
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getTransactionReceiptById(transactionId: string): Observable<any>{
        return this.http.get(RestEndpoint.getTransactionReceiptById, { params: { transactionId: transactionId}})
            .pipe(
                map((result: any) => {
                    return result;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    /**
     * Used to receive user balance updates.
     * Updates are triggered every time the getBalance route is triggered and data is returned.
     * @returns the balance of the user logged into the Digital Bank
     */
    public getBalanceSubscription(): Observable<number> {
        return this.userBankingBalance.asObservable();
    }

    private setBalanceSubscription(userBalance: number): void {
        this.userBankingBalance.next(userBalance);
    }

    public getHistory(accountHistoryRequest: IAccountHistoryRequest): Observable<AccountHistoryEntries[]>{
        return this.http.get(RestEndpoint.banking.account.getHistory, { params: {
                accountId: accountHistoryRequest.accountId, 
                documentNumber: accountHistoryRequest.documentNumber, 
                initialDate: accountHistoryRequest.initialDate, 
                endDate: accountHistoryRequest.endDate, 
                page: accountHistoryRequest.page,
                limit: accountHistoryRequest.limit,
                onlyPixEntries: accountHistoryRequest.onlyPixEntries }})
            .pipe(
                map((result: IAccountHistoryResponse) => {
                    return result?.data?.movements;
                }),
                catchError((err) => {
                    throw err;
                })
                );
    }

    public generateInternalTransferRequest(internalTransferRequest: IInternalTransferRequest): Observable<IInternalTransferResponse>{
        const payloadEncrypted: PayloadEncrypted = new PayloadEncrypted(internalTransferRequest, BankingService.RSA_KEY);

        return this.http.post(RestEndpoint.banking.account.generateInternalTransferRequest, {
            param0: this.transactionPasswordEncrypted,
                param1: payloadEncrypted.payload
            })
            .pipe(
                map((data: IInternalTransferResponse) => {
                    const { accountId, documentNumber } = this.userBankingAccount;
                    this.getBalance({accountId, documentNumber}).subscribe();
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public generateExternalTransferRequest(externalTransferRequest: IExternalTransferRequest): Observable<IExternalTransferResponse>{
        const payloadEncrypted: PayloadEncrypted = new PayloadEncrypted(externalTransferRequest, BankingService.RSA_KEY);

        return this.http.post(RestEndpoint.banking.account.generateExternalTransferRequest, {
            param0: this.transactionPasswordEncrypted,  
            param1: payloadEncrypted.payload
        })
        .pipe(
            map((data: IExternalTransferResponse) => {
                const { accountId, documentNumber } = this.userBankingAccount;
                this.getBalance({accountId, documentNumber}).subscribe();
                return data;
            }),
            catchError((err) => {
                throw err;
            })
        );
    }

    public validatePassword(password: string): Observable<void> {
        const transactionPasswordEncrypted : TransactionPasswordEncrypted = new TransactionPasswordEncrypted(password, BankingService.RSA_KEY)
      
        return this.http.post<void>(RestEndpoint.banking.account.validatePassword, { param0: transactionPasswordEncrypted.password })
            .pipe(map(() => {
                this.transactionPasswordEncrypted = transactionPasswordEncrypted.password;
                }),
                catchError((err) => {
                    throw err;
                })
            )
    }
    
    public changePassword(password: string, validationCode: string): Observable<void> {
        const transactionPasswordEncrypted : TransactionPasswordEncrypted = new TransactionPasswordEncrypted(password, BankingService.RSA_KEY)
        
        return this.http.post<void>(RestEndpoint.banking.account.changePassword, { param0: transactionPasswordEncrypted.password, param1: validationCode })
        .pipe(
            catchError((err) => {
                throw err;
            })
        );
    }
    
    public createStaticQRCode(request: QRCodeCreationRequest): Observable<QRCodeCreationResponse> {
        return this.http.post(RestEndpoint.banking.pix.createStaticQRCode, request)
            .pipe(
                map((res: { data: QRCodeCreationResponse}) => {
                    if (!res?.data) {
                        throw new Error('Cannot create the Static QR Code');
                    }
                    return res.data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getStaticQRCode(request: QRCodeInputRequest): Observable<QRCodeDataResponse> {
        return this.http.get(RestEndpoint.banking.pix.getStaticQRCode, { params: { transactionId: request?.transactionId, userId: request?.userId }})
            .pipe(
                map((res: { data: QRCodeDataResponse}) => {
                    if (!res?.data) {
                        throw new Error('Cannot get the Static QR Code data');
                    }
                    return res.data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public checkPixKey(request: IAccountInfoByPixKeyRequest): Observable<AccountInfoByPixKeyResponse> {
        return this.http.post(RestEndpoint.banking.pix.getAccountInfoByPixKey, request)
            .pipe(
                map((res: { data: AccountInfoByPixKeyResponse}) => {
                    if (!res?.data) {
                        throw new Error('Unable to check PIX key');
                    }
                    return res.data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public transferByPix(request: PixTransferRequest): Observable<any> {
        return this.http.post(RestEndpoint.banking.pix.transfer, request)
            .pipe(
                map((res: {data: { body: PixTransferResponse}}) => {
                    if (!res?.data?.body) {
                        throw new Error('Unable to transfer by Pix');
                    }
                    const { accountId, documentNumber } = this.userBankingAccount;
                    this.getBalance({accountId, documentNumber}).subscribe();
                    return res.data?.body;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getQRCodeDataByEmv(request: QRCodeEMVInputRequest): Observable<QRCodeEMVResponse> {
        return this.http.post(RestEndpoint.banking.pix.getQRCodeDataByEmv, request)
            .pipe(
                map((res: { data: QRCodeEMVResponse}) => {
                    if (!res?.data) {
                        throw new Error('Unable to get the QR Code by EMV');
                    }
                    return res.data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getAccountInfoNaturalPerson(accountInfoRequest: IAccountInfoRequest): Observable<IAccountInfoResponse> {
        return this.http.get(RestEndpoint.banking.account.getAccountInfoNaturalPerson, { params: { 
            accountId: accountInfoRequest.accountId, 
            documentNumber: accountInfoRequest.documentNumber }})
            .pipe(
                map((res: { data: IAccountInfoResponse}) => {
                    return res.data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getAccountInfoLegalPerson(accountInfoRequest: IAccountInfoRequest): Observable<IAccountInfoLegalPersonResponse> {
        return this.http.get(RestEndpoint.banking.account.getAccountInfoLegalPerson, { params: { 
            accountId: accountInfoRequest.accountId, 
            documentNumber: accountInfoRequest.documentNumber }})
            .pipe(
                map((res: { data: IAccountInfoLegalPersonResponse}) => {
                    return res.data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public updateAccountInfoLegalPerson(updateInfoRequest: UpdateAccountInfoRequest): Observable<IAccountInfoLegalPersonResponse> {
        return this.http.put(RestEndpoint.banking.account.updateAccountInfoLegalPerson, { 
                businessEmail: updateInfoRequest.businessEmail,
                businessPhoneNumber: updateInfoRequest.businessPhoneNumber
            })
            .pipe(
                map((data: {data: IAccountInfoLegalPersonResponse}) => {
                    return data?.data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public CreateCharge(dt: any): Observable<any> {
        return this.http.post(RestEndpoint.CreateCharge, dt)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public CheckCharge(dt: any): Observable<any> {
        return this.http.post(RestEndpoint.CheckCharge, dt)
            .pipe(
                map((data: any) => {
                    try {
                        return JSON.parse(data);
                    } catch(err) {
                        return data;
                    }
                }),
                catchError((err) => {
                    throw err;
                })
            );
    }

    public getContracts(): Observable<ContractRequest[]> {
        return this.http.get(RestEndpoint.getContractsByCustomerId)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                }));
    }

    public updateContract(contract: ContractRequest): Observable<boolean> {
        return this.http.put(RestEndpoint.updateContractByCustomer, contract)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                }));
    }

    public realizeManualPayment(contractPayment: ContractPayments): Observable<boolean> {
        return this.http.post(RestEndpoint.realizeManualPayment, contractPayment)
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError((err) => {
                    throw (err);
                }));
    }
}